import { render, staticRenderFns } from "./statistics-users-analysis.vue?vue&type=template&id=4bc32a80&scoped=true&"
import script from "./statistics-users-analysis.vue?vue&type=script&lang=ts&"
export * from "./statistics-users-analysis.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc32a80",
  null
  
)

export default component.exports