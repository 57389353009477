import { PeriodMetricsFields } from "@/includes/types/UsersStatByPeriod/types";

export default function getMetricColor(metric: keyof PeriodMetricsFields):string {
  if (metric === 'reply_count') {
    return 'rgba(var(--a-primary), 1)'
  } else if (metric === 'message_count') {
    return 'rgba(var(--a-success), 1)'
  } else if (metric === 'trigger_call') {
    return 'rgba(var(--a-danger), 1)'
  } else if (metric === 'reputation_take') {
    return 'rgba(var(--a-warning), 1)'
  }  else if (metric === 'reputation_add') {
    return 'rgba(var(--a-dark), 1)'
  }  else if (metric === 'ap_take') {
    return '#048A81'
  } else if (metric === 'ap_add') {
    return '#FFB7C3'
  } else if (metric === 'xp_take') {
    return '#497f43'
  } else if (metric === 'xp_add') {
    return '#b1a7a6'
  } else if (metric === 'other_command') {
    return '#EECF6D'
  } else if (metric === 'punish_command_take') {
    return '#C5EBC3'
  } else if (metric === 'punish_command_add') {
    return '#A790A5'
  } else if (metric === 'warn_command_take') {
    return '#4B5267'
  } else if (metric === 'warn_command_add') {
    return '#F7C59F'
  } else {
    return '#004E89'
  }
}
