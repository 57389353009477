

































































import SetPeriod from '@/views/chat/statistics/components/mainStatistics/setPeriod.vue'
import StatisticsApiService from '@/includes/Api/StatisticsApiService'
import UserStatGlobalCard from '@/components/UsersStatByPeriod/UserStatGlobalCard.vue'
import { errorNotification } from '@/includes/NotificationService'
import UserStatUsersData from '@/components/UsersStatByPeriod/UserStatUsersData.vue'
import { InputSetups } from '@/mixins/input-setups'
import { UserStatDataRecord } from './types'
import { StatPeriodGroup, StatPeriodMetrics } from '@/includes/types/UsersStatByPeriod/Enums'
import { PeriodMetricsFields, UsersStatPeriod } from '@/includes/types/UsersStatByPeriod/types'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    UserStatUsersData,
    UserStatGlobalCard,
    SetPeriod,
    EmptyData
  }
})
export default class UsersStatByPeriod extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  period: {from:string, to: string} = {
    from: moment().add(-3, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  }

  userMetrics:Array<UserStatDataRecord> = []

  selectedMetrics: Array<StatPeriodMetrics> = [ StatPeriodMetrics.MessageCount, StatPeriodMetrics.ReplyCount, StatPeriodMetrics.ReputationTake ]

  group: StatPeriodGroup = StatPeriodGroup.WEEKS

  usersStatByPeriod: UsersStatPeriod | null = null

  get dateLabels():Array<string> {
    if (this.usersStatByPeriod) {
      return this.usersStatByPeriod.total.map(record => record.date)
    }

    return []
  }

  get periodGroupOptions():Array<SelectOption> {
    return Object.keys(StatPeriodGroup).map(key => {
      return { label: this.$t(`period_group_${ key.toLowerCase() }_title`).toString(), value: key }
    })
  }

  get metricOptions():Array<SelectOption> {
    return Object.keys(StatPeriodMetrics).map(key => {
      return { label: this.$t(`period_metric_${ key.toLowerCase() }_title`).toString(), value: key }
    })
  }

  get selectedMetricsInSnakeCase(): Array<keyof PeriodMetricsFields> {
    return this.selectedMetrics.slice().map(snakeCase) as Array<keyof PeriodMetricsFields>
  }

  handleChange(newState:StatPeriodGroup) {
    if (this.group === newState) {
      this.group = StatPeriodGroup.WEEKS
    } else {
      this.group = newState
    }

    this.requestStat()
  }

  validateRequest():Promise<boolean | string> {
    return new Promise<boolean>((resolve, reject) => {
      if (moment(this.period.from).diff(moment(this.period.to), 'd') < -360) {
        reject(this.$t('period_limit'))
      } else if (this.selectedMetrics.length <= 0) {
        reject(this.$t('empty_metrics_notification'))
      } else {
        resolve(true)
      }
    })
  }

  getUserStatByPeriod() {
    this.$baseTemplate.loader.open()

    StatisticsApiService.getUsersStatByPeriod('tg', {
      chat_id: Number.parseInt(this.$route.params[EntityTypes.CHAT_ID]),
      from: this.period.from,
      to: this.period.to,
      group: this.group,
      metrics: this.selectedMetrics,
    })
      .then(({ data }) => {
        this.usersStatByPeriod = data
      })
      .catch(errorNotification)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  requestStat():void {
    this.validateRequest()
      .then(this.getUserStatByPeriod)
      .catch(errorNotification)
  }

  created() {
    this.requestStat()
  }
}
