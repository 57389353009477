















import UsersStatByPeriod from '@/components/UsersStatByPeriod/UsersStatByPeriod.vue'
import PageTitle from '@/components/PageTitle.vue'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    NotAvailableOptionsOverlay,
    UsersStatByPeriod,
    PageTitle,
    MobileLandscapeTriggerLayout
  }
})
export default class StatisticsUsersAnalyze extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {
  mounted():void {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed():void {
    this.$baseTemplate.saveButton.show()
  }
}
