







































import getMetricColor from '@/components/UsersStatByPeriod/logic/getMetricColor'
import { PeriodMetricsFields, PeriodMetricsTotalAlias, UsersStatPeriod } from '@/includes/types/UsersStatByPeriod/types'
import { StatPeriodGroup } from '@/includes/types/UsersStatByPeriod/Enums'
import { GlobalMetricRecord } from '@/components/UsersStatByPeriod/types'

import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ChartData } from 'piramis-base-components/src/components/NewCharts/types/BaseTypes'

import { Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Vue from 'vue'
import convertLabels from '@/components/UsersStatByPeriod/logic/convertLabels'

@Component({
  components: {
    NewColumnChart
  }
})
export default class UserStatGlobalCard extends Vue {
  @Prop({ type: Object }) statistics!: UsersStatPeriod

  @Prop({ type: Array }) metrics!: Array<keyof PeriodMetricsFields>

  @Prop({ type: String }) group!: StatPeriodGroup

  @Prop({ type: Array }) dateLabels!: Array<string>

  @Prop() period!: { from: string, to: string }

  updateChartKey = 0

  globalMetrics: Array<GlobalMetricRecord> | null = null

  @Watch('statistics', { deep: true })
  onStatChange(newStat:UsersStatPeriod):void {
    this.setData(newStat)
      .then(data => {
        this.globalMetrics = data
        this.updateChartKey += 1
      })
  }

  getChartData(totals: UsersStatPeriod['total'], metric: keyof PeriodMetricsFields):ChartData {
    moment.locale(this.$i18n.locale)

    const labels: ChartData['labels'] = this.dateLabels
    const series: ChartData['series'] = [ {
      name: this.$t(`series_name_${ metric }`).toString(),
      data: totals.map(t => t[metric] ?? 0)
    } ]

    return {
      series,
      labels: convertLabels(labels)
    }
  }

  setData(rootStat:UsersStatPeriod):Promise<Array<GlobalMetricRecord>> {
    return new Promise(resolve => {
      const stat = this.metrics
        .slice()
        .map(metric => {
          const totalMetric: PeriodMetricsTotalAlias = `total_${ metric }`

          return {
            name: metric,
            total: rootStat[totalMetric] ?? '-',
            chartInfo: this.group === StatPeriodGroup.NONE ? null : this.getChartData(rootStat.total, metric)
          }
        })

      resolve(stat)
    })
  }

  columnOptions(metric: keyof PeriodMetricsFields): ColumnChartOptions{
    return {
      chart: {
        height: 100,
        zoom: false,
        margins: {
          right: 0,
          left: 0,
          top: 0,
          bottom: 5
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      colors: [ getMetricColor(metric) ],
      tooltip: {
        visible: true,
        showName: false,
        colorTip: false,
      },
    }
  }

  mounted():void {
    this.setData(this.statistics)
      .then(data => {
        this.globalMetrics = data
        this.updateChartKey += 1
      })
  }
}
