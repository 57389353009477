import Api from "@/includes/logic/Api";
import { UsersStatPeriod, UsersStatRequestBody } from "@/includes/types/UsersStatByPeriod/types";

export default class StatisticsApiService {

  static getUsersStatByPeriod(type:'tg', body: UsersStatRequestBody): Promise<{data:UsersStatPeriod}> {
    return Api.sendRequest(type, 'getusersstatbyperiod', body)
  }

}
