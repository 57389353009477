var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-stat-by-period"},[_c('div',{staticClass:"flex flex-col lg:flex-row lg:justify-between"},[_c('select-input',{staticClass:"w-full lg:w-1/2 users-stat-by-period__metrics",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': this,
          'key': 'selectedMetrics',
          'options': _vm.metricOptions,
          'multiple': true,
          'validation': 'required'
        }
      }},on:{"input":_vm.requestStat}}),_c('div',{staticClass:"w-full lg:w-1/2"},[_c('set-period',{on:{"searchPeriod":_vm.requestStat},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('div',{staticClass:"period-group flex justify-end flex-wrap mt-2 lg:mt-0"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('period_groups_title')))]),_vm._l((_vm.periodGroupOptions),function(gr){return [_c('a-checkable-tag',{key:gr.value,staticClass:"cursor-pointer last:mr-0",attrs:{"checked":_vm.group === gr.value},on:{"change":function($event){return _vm.handleChange(gr.value)}}},[_vm._v(" "+_vm._s(gr.label)+" ")])]})],2)],1)],1),(_vm.selectedMetrics.length > 0 && _vm.usersStatByPeriod)?[_c('user-stat-global-card',{key:"cards",staticClass:"mt-base",attrs:{"statistics":_vm.usersStatByPeriod,"metrics":_vm.selectedMetricsInSnakeCase,"date-labels":_vm.dateLabels,"group":_vm.group,"period":_vm.period}}),_c('user-stat-users-data',{key:"users",staticClass:"mt-base",attrs:{"statistics":_vm.usersStatByPeriod,"metric-columns":_vm.selectedMetricsInSnakeCase,"date-labels":_vm.dateLabels,"group":_vm.group,"period":_vm.period}})]:_c('empty-data',{attrs:{"description":_vm.$t('empty_metrics_notification')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }