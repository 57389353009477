import { render, staticRenderFns } from "./UsersStatByPeriod.vue?vue&type=template&id=4c5a1008&scoped=true&"
import script from "./UsersStatByPeriod.vue?vue&type=script&lang=ts&"
export * from "./UsersStatByPeriod.vue?vue&type=script&lang=ts&"
import style0 from "./UsersStatByPeriod.vue?vue&type=style&index=0&id=4c5a1008&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5a1008",
  null
  
)

export default component.exports