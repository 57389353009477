


























































import {
  PeriodMetricsFields, PeriodMetricsMaxFields,
  UsersStatPeriod, UsersStatPeriodTotalRecord,
  UsersStatPeriodUserRecord
} from '@/includes/types/UsersStatByPeriod/types'
import {
  BaseUserStatRecord,
  UserStatChartInfo,
  UserStatDataRecord,
  UserStatValues
} from '@/components/UsersStatByPeriod/types'
import { StatPeriodGroup } from '@/includes/types/UsersStatByPeriod/Enums'
import getMetricColor from '@/components/UsersStatByPeriod/logic/getMetricColor'
import TableSearch from '@/components/UsersStatByPeriod/TableSearch.vue'
import trimAndLowCaseFormat from '@/components/UsersStatByPeriod/logic/trimAndLowCaseFormat'
import convertLabels from '@/components/UsersStatByPeriod/logic/convertLabels'

import { ChartData } from 'piramis-base-components/src/components/NewCharts/types/BaseTypes'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { merge, maxBy, cloneDeep } from 'lodash'

@Component({
  components: {
    TableSearch,
    UserMainInfo,
    NewColumnChart,
  }
})
export default class UserStatUsersData extends Vue {
  @Prop({ type: Object }) statistics!: UsersStatPeriod

  @Prop({ type: Array }) metricColumns!: Array<keyof PeriodMetricsFields>

  @Prop({ type: String }) group!: StatPeriodGroup

  @Prop({ type: Array }) dateLabels!: Array<string>

  @Prop() period!: { from: string, to: string }

  @Watch('statistics', { deep: true })
  onStatChange(newStat: UsersStatPeriod):void {
    this.tableSearchKey += 1

    this.userMetricsData(newStat.users, newStat)
      .then(res => {
        this.tableData = res
        this.updateKey()
      })
  }

  updateTableKey = 0

  tableSearchKey = 0

  tableData: Array<UserStatDataRecord> = []

  usersDataBackup: Array<UserStatDataRecord> = []

  loading = false

  get columns() {
    return [
      {
        title: this.$t('stat_users_data_table_col_user'),
        key: 'user-info',
        width: 270,
        scopedSlots: { customRender: 'user-info' },
      },
      ...this.metricColumns.map(metric => ({
        title: this.$t(metric),
        key: metric,
        width: 250,
        scopedSlots: { customRender: metric },
        defaultSortOrder: 'descend',
        sorter: (a:UserStatDataRecord, b:UserStatDataRecord) => (a.total[metric] ?? 0) - (b.total[metric] ?? 0)
      }))
    ]
  }

  userNamesFilter(value: UsersStatPeriodUserRecord, usersToCheck:Array<string> | string):boolean {
    return (!!value.name && usersToCheck.includes(trimAndLowCaseFormat(value.name))) ||
      (!!value.login &&
        (
          usersToCheck.includes(trimAndLowCaseFormat(value.login)) ||
          usersToCheck.includes(trimAndLowCaseFormat(`@${ value.login }`))
        )
      )
  }

  userSearchQueryFilter(value: UsersStatPeriodUserRecord, searchString: string):boolean {
    const query = trimAndLowCaseFormat(searchString)

    return (!!value.name && trimAndLowCaseFormat(value.name).includes(query)) ||
      (!!value.login &&
        (
          trimAndLowCaseFormat(value.login).includes(query) ||
          trimAndLowCaseFormat(`@${ value.login }`).includes(query)
        )
      )
  }

  findMaxMetricValue(array: Array<UsersStatPeriodTotalRecord>, metric: keyof PeriodMetricsFields):number {
    const maxValue = maxBy(array, (v) => v[metric])

    if (maxValue) {
      return maxValue[metric] ?? 0
    }

    return 0
  }

  getNewMaxValues(users:Array<UsersStatPeriodUserRecord>):PeriodMetricsMaxFields {
    const usersCopy = cloneDeep(users)
    const flatMax = usersCopy.map(u => u.data).flat()

    return this.metricColumns.reduce((resObj:PeriodMetricsMaxFields, metric) => {
      this.$set(resObj, `max_${ metric }`, this.findMaxMetricValue(flatMax, metric))

      return resObj
    }, {})
  }

  handleHideUsers(names: Array<string>) {
    const users = this.statistics.users.slice().filter(value => !this.userNamesFilter(value, names))

    this.loading = true

    setTimeout(() => {
      this.userMetricsData(users, this.getNewMaxValues(users))
        .then(res => {
          this.tableData = res
          this.updateKey()
          this.loading = false
        })
    }, 0)

  }

  handleShowUsers(names: Array<string>) {
    const users = this.statistics.users.slice().filter(value => this.userNamesFilter(value, names))

    this.loading = true

    setTimeout(() => {
      this.userMetricsData(users, this.getNewMaxValues(users))
        .then(res => {
          this.tableData = res
          this.updateKey()
          this.loading = false
        })
    }, 0)
  }

  generateColumnOptions(metric: keyof PeriodMetricsFields, options: Partial<ColumnChartOptions>):ColumnChartOptions {
    const defaultSetting = {
      chart: {
        height: 70,
        zoom: false,
        margins: {
          right: 0,
          left: 0,
          top: 0,
          bottom: 5
        }
      },
      xAxis: {
        visible: true,
      },
      yAxis: {
        visible: false,
      },
      colors: [ getMetricColor(metric) ],
      tooltip: {
        visible: true,
        showName: false,
        colorTip: false,
      },
    }

    return merge(defaultSetting, options)
  }

  generateChartData(userData: UsersStatPeriodUserRecord['data'], metric: keyof PeriodMetricsFields, responseData: UsersStatPeriod | PeriodMetricsMaxFields) {
    const labels = this.dateLabels

    const setSeriesData = (userData:UsersStatPeriodUserRecord['data'], metric:keyof PeriodMetricsFields):Array<number> => {
      const array = Array(labels.length).fill(0)

      userData.forEach(record => {
        let dateIndex = labels.indexOf(record.date);

        if (dateIndex !== -1) {
          array[dateIndex] = record[metric] ?? 0;
        }
      })

      return array
    }

    return {
      key: metric,
      options: this.generateColumnOptions(metric, { yAxis: { max: responseData[`max_${ metric }`] } }),
      chart: {
        labels: convertLabels(labels),
        series: [ {
          name: metric,
          data: setSeriesData(userData, metric)
        } ]
      }
    }
  }

  generateChartInfo(userData: UsersStatPeriodUserRecord['data'], responseData: UsersStatPeriod | PeriodMetricsMaxFields):any {
    return this.metricColumns
      .map(metric => this.generateChartData(userData, metric, responseData))
      .flat()
  }

  generateValues(userData: UsersStatPeriodUserRecord['data']): Array<UserStatValues> {
    const parseMetricValues = (metric: keyof PeriodMetricsFields): Array<UserStatValues> => {
      return userData.map(record => ({
        key: metric,
        [metric]: record[metric] ?? 0
      }))
    }

    return this.metricColumns
      .map(parseMetricValues)
      .flat()
  }

  prepareUserStatistics(userData: UsersStatPeriodUserRecord['data'], responseData: UsersStatPeriod | PeriodMetricsMaxFields): Pick<UserStatDataRecord, 'values' | 'chartInfo'> {
    if (this.group === StatPeriodGroup.NONE) {
      return {
        values: this.generateValues(userData)
      }
    } else {
      return {
        chartInfo: this.generateChartInfo(userData, responseData)
      }
    }
  }

  userMetricsData(users: UsersStatPeriod['users'], fullStatistics: UsersStatPeriod | PeriodMetricsMaxFields):Promise<Array<UserStatDataRecord>> {
    return new Promise<Array<UserStatDataRecord>>((resolve) => {
      const data = users.map(user => {
        return {
          ...user,
          ...this.prepareUserStatistics(user.data, fullStatistics)
        }
      })

      resolve(data)
    })
  }

  setDefaultTableData():void {
    this.tableData = this.usersDataBackup.slice();
    this.updateKey()
  }

  handleSearchQuery(search:string):void {
    this.tableData = this.usersDataBackup.filter(value => this.userSearchQueryFilter(value, search))
    this.updateKey()
  }

  getStatInfoByKey<T extends BaseUserStatRecord>(userData: Array<T>, metric: keyof PeriodMetricsFields):T | undefined {
    return userData.find(ud => ud.key === metric)
  }

  chartInfoValid(userData: Array<UserStatChartInfo>, metric: keyof PeriodMetricsFields):boolean {
    return userData && this.getChartDataByMetricName(userData, metric) !== null && this.getChartOptionsByMetricName(userData, metric) !== null
  }

  getChartDataByMetricName(userData: Array<UserStatChartInfo>, metric: keyof PeriodMetricsFields): ChartData | null {
    const record = this.getStatInfoByKey(userData, metric)

    if (record) {
      return record.chart
    }

    return null
  }

  getChartOptionsByMetricName(userData: Array<UserStatChartInfo>, metric: keyof PeriodMetricsFields): ColumnChartOptions | null {
    const record = this.getStatInfoByKey(userData, metric)

    if (record) {
      return record.options
    }

    return null
  }

  getFixedValuesByKey(userData: Array<UserStatValues>, metric: keyof PeriodMetricsFields):number | string {
    const record = this.getStatInfoByKey(userData, metric)

    if (record) {
      const recordMetric = record[metric]

      if (recordMetric) {
        return recordMetric
      }
    }

    return '-'
  }

  updateKey():void {
    this.updateTableKey += 1
  }

  mounted():void {
    this.userMetricsData(this.statistics.users, this.statistics)
      .then(res => {
        this.tableData = res
        this.usersDataBackup = res
      })
      .then(this.updateKey)
  }
}
